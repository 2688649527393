const FORM = {
    UPDATE: {
        CONTENT: 'form-update-content',
        FILE: 'form-update-file',
        NAME: 'form-update-name',
        CREDIT: 'form-update-credit',
        EMAIL: 'form-update-email',
        URL: 'form-update-url',
        VIRUSTEXT: 'form-update-virus-text',
        PHONE: {
            EXT: 'form-update-phone-ext',
            NUMBER: 'form-update-phone-number',
        },
    },
    TOGGLE: {
        CHECKBOX: {
            FIRST: 'form-toggle-checkbox-first',
            SECOND: 'form-toggle-checkbox-second',
            THIRD_MOBILE: 'form-toggle-checkbox-third-mobile',
        },
    },
    SET: {},
    CLEAR: {},
};

const ACTIONS = {
    FORM,
};

export default ACTIONS;
