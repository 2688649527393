import { useEffect } from 'react';
import Header from 'components/common/Header/index';
import FormSection from './FormSection';
import css from './Intro.module.scss';

export default function Intro() {
    useEffect(() => {
        window.location.replace('https://www.ynet.co.il/redmail');
    }, []);

    return (
        <div className={css.intro}>
            {/* <Header />
            <FormSection /> */}
        </div>
    );
}
