import ACTIONS from 'constants/actions';
import ENV from 'constants/environment';
import React, { createContext, useReducer } from 'react';
import _ from 'lodash';

export type FormType = {
    content: string;
    files: [any];
    name: string;
    credit: string;
    email: string;
    url: string;
    virusText: string;
    phone: {
        ext: string;
        number: string;
    };
    checkbox: {
        first: boolean;
        second: boolean;
        third: boolean;
    };
};

export type ActionType = {
    type: string;
    payload?: any;
};

const initialForm: FormType = {
    content: '',
    files: [null],
    name: '',
    credit: '',
    email: '',
    url: '',
    virusText: '',
    phone: {
        ext: '050',
        number: '',
    },
    checkbox: {
        first: false,
        second: false,
        third: false,
    },
};

const reducer = (form: FormType, action: ActionType): FormType => {
    if (ENV.IS_DEV) {
        console.log(`${String(action.type).replace(/-/g, '_').toUpperCase()}`, action.payload);
    }

    switch (action.type) {
        case ACTIONS.FORM.UPDATE.CONTENT:
            return _.set(form, 'content', action.payload?.data);

        case ACTIONS.FORM.UPDATE.FILE:
            return _.set(form, 'files', action.payload?.data);

        case ACTIONS.FORM.UPDATE.NAME:
            return _.set(form, 'name', action.payload?.data);

        case ACTIONS.FORM.UPDATE.CREDIT:
            return _.set(form, 'credit', action.payload?.data);

        case ACTIONS.FORM.UPDATE.EMAIL:
            return _.set(form, 'email', action.payload?.data);

        case ACTIONS.FORM.UPDATE.URL:
            return _.set(form, 'url', action.payload?.data);

        case ACTIONS.FORM.UPDATE.VIRUSTEXT:
            return _.set(form, 'virusText', action.payload?.data);

        case ACTIONS.FORM.UPDATE.PHONE.EXT:
            return _.set(form, 'phone.ext', action.payload?.data);

        case ACTIONS.FORM.UPDATE.PHONE.NUMBER:
            return _.set(form, 'phone.number', action.payload?.data);

        case ACTIONS.FORM.TOGGLE.CHECKBOX.FIRST:
            return _.set(form, 'checkbox.first', !form.checkbox.first);

        case ACTIONS.FORM.TOGGLE.CHECKBOX.SECOND:
            return _.set(form, 'checkbox.second', !form.checkbox.second);

        case ACTIONS.FORM.RESET:
            return initialForm;

        case ACTIONS.FORM.TOGGLE.CHECKBOX.THIRD_MOBILE:
            return _.set(form, 'checkbox.third', !form.checkbox.third);

        default:
            throw new Error();
    }
};

const defaultDispatch: React.Dispatch<ActionType> = () => initialForm;

const FormContext = createContext({
    form: initialForm,
    dispatch: defaultDispatch,
});

export { FormContext, FormProvider };

const FormProvider: React.FC = ({ children }) => {
    const [form, dispatch] = useReducer<React.Reducer<FormType, ActionType>>(reducer, initialForm);

    return <FormContext.Provider value={{ form, dispatch }}>{children}</FormContext.Provider>;
};
