import React from 'react';
import ReactDOM from 'react-dom';
import Intro from './components/pages/Intro';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase';
import firebaseConfig from './firebase.json';
import 'styles/App.scss';
import { FormProvider } from 'utils/FormContext';

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
    <FormProvider>
        <React.StrictMode>
            <Intro />
        </React.StrictMode>
    </FormProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
